const firebaseConfig = {
    apiKey: "AIzaSyBK5n3H5ar_50k0HujLqkedMfnCzHsOCrw",
    authDomain: "uofarkansas-58631.firebaseapp.com",
    projectId: "uofarkansas-58631",
    storageBucket: "uofarkansas-58631.appspot.com",
    messagingSenderId: "937519501122",
    appId: "1:937519501122:web:beedd0056727efc783c2db",
    measurementId: "G-TRDDXXEYQH"
};

export default firebaseConfig;
