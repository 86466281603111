import { useState } from 'react';
import { observer } from 'mobx-react';
import { AutoSizer } from 'react-virtualized';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';
import { henshu, useHenshu } from '@strategies/henshu';

import Section from '../Section';
import { Callout, Pin } from '../MapIcons/MapIcons';

import sunIcon from '../../assets/map-icons/sun.svg';
import cameraIcon from '../../assets/map-icons/camera.svg';
import globeIcon from '../../assets/map-icons/globe.svg';
import usersIcon from '../../assets/map-icons/users.svg';
import walkPath from '../../assets/map-icons/walk-path.png';
import { ENGAGEMENT_LINK } from '../../config';


export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);

    return (
        <div className="Welcome-wrap">
            <Section name="Welcome" index={0}>
                <Anchor onChange={(anchor: any) => setPromptHidden(anchor.placement === 'offscreen')} />

                <div className="links">
                    {/*(}
                    <henshu.richtext {...bindTo('calendar.body')} />
                    */}

                    {/* <henshu.a
                        className="button"
                        href={ENGAGEMENT_LINK}
                        target={'_blank'}
                        {...bindTo('enegage.calendar.button')}
                    /> */}
                </div>

                {/*}<henshu.p {...bindTo('Welcome.body')} />*/}

               
            </Section>

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight - 100, left: 0, behavior: 'smooth' })}
            >
                {/* <FiChevronDown /> */}
            </footer>
        </div>
    );
});
