import { useState } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { List, Accordion, Title, Header, Body } from '@strategies/ui';
import { useHenshuApp } from '@strategies/henshu-app';

import Section from '../Section';



export default observer(function Process() {
    const { bindTo } = useHenshu();
    const { upload } = useHenshuApp().persist;
    return (
        <Section index={2} name="Process">
              <henshu.richtext {...bindTo('engage.Process.body')} />

              <div className="image-wrap">
                         <henshu.img
                             get={() => bindTo('engage.Process.image').get()}
                             set={async (t) => bindTo('engage.Process.image').set(t ? await upload(t) : t)}
                         />
                     </div>
        </Section>
    );
});
