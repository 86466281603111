import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { WatchAnchors } from '@strategies/react-anchors';
import ReactMobilized from '@strategies/react-mobilized';

import './App.scss';

import { ContextData, Context } from './context';
import { ENGAGEMENT_LINK, MENU, MOBILE_BREAKPOINT } from './config';
import Welcome from './components/Welcome/Welcome';
import Survey from './components/Survey/Survey';
import Vision from './components/Vision/Vision';
import Contact from './components/Contact/Contact';
import Process from './components/Process/Process';
import Resources from './components/Resources/Resources';
import ArrowIcon from './assets/arrow-brand.svg';
import Logo from './assets/ar_univ_WCOB.png';
import MobileLogo from './assets/UA_Logo.png';

import React from 'react';


export default observer(function App() {
    const { bindTo } = useHenshu();
    const [data, setData] = useState<ContextData>({ section: 0 });
    const update = useCallback((changes: ContextData) => setData({ ...data, ...changes }), [data, setData]);

    const { section } = data;

    const context = { ...data, update };

    return (
        <div className="App">
            <ReactMobilized
                    // customAnchor={(anchor:any)=>{
                    //     return <a href={(anchor.href === "#share") ? ENGAGEMENT_LINK : anchor.href} target="_blank">
                    //                 {anchor.text}
                    //             </a>
                    // }}
                anchors={MENU}
                // banner={<henshu.a href={ENGAGEMENT_LINK}
                // target={'_blank'} {...bindTo('banner')} />}
                logo={
                <>
                <img className={'desktoplogo'} src={Logo} alt="Logo" />
                <img className={'mobilelogo'} src={MobileLogo} alt="Logo" />
                {/* <henshu.h1 {...bindTo('title')} /> */}
                </>}
                breakpoint={MOBILE_BREAKPOINT}
                section={section! + 1}
                setCurrentSection={(n: number) => update({ section: n - 1 })}
            >
                <Context.Provider value={context}>
                    <WatchAnchors>
                        <Welcome />
                        <Vision />
                        <Process />
                        {/* <Contact /> */}
                    </WatchAnchors>
                </Context.Provider>

                <footer>
                    <div className="logo">
                    <img className={'desktoplogo'} src={Logo} alt="Logo" />
                    <img className={'mobilelogo'} src={MobileLogo} alt="Logo" />
                        {/* <henshu.h3 {...bindTo('title')} /> */}
                    </div>

                    <button onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                        <img src={ArrowIcon} alt="Back to top" />
                    </button>
                </footer>
            </ReactMobilized>
        </div>
    );
});
