/*
 * Configuration
 */

export const ENGAGEMENT_LINK = 'https://waltoncollege.nudge.sasaki.com/';

export const HEAP_ID = '';

export const FORMSPREE_URL = 'https://formspree.io/f/xjvqpear';

export const MOBILE_BREAKPOINT = 1200;

export const MENU = [
    { text: 'Welcome', href: '#welcome' },
    { text: 'Vision', href: '#vision' },
    { text: 'Process', href: '#process' },
    // { text: 'MyBuilding Tool', href: '#share' },
];
